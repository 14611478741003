import {post} from '@/utils/axios'

export const getMorality = (params) => post('/shida-user-biz/Health/getHealth',params)

export const getCompetionDetail = (params) => post('/shida-user-biz/TbMatch/getSportMatchDetail',params)

export const getQuestionNaire = (params) => post('/shida-user-biz/TbQuestionnaire/getQuestionnaireAndLibrary',params)

export const submitTest = (params) => post('/shida-user-biz/TbSubmitEva/saveOrUpdate',params)

export const getTestDetail = (params) => post('/shida-user-biz/TbEvaluation/getEvaResultDetailByUserId',params)

export const getWejiDetail = params => post('/shida-user-biz/TbBreak/getHealthBreakDetail', params)

export const getAttendanceDetail = params => post('/shida-user-biz/TbAttendanceStudent/getData', params)

// 学生获取测评题目
export const getEvaTopics = params => post('/shida-user-biz/TbEvaluation/getEvaTopics', params)

// 学生提交测评
export const submitEvaTopics = params => post('/shida-user-biz/TbEvaluationSubmit/saveOrUpdate', params)

// 获取测评详细(学生/老师)
export const getEvaResult = params => post('/shida-user-biz/TbEvaluation/getEvaResultDetailByUserId', params)
