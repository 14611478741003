<template>
	<div class="close-n-go-back">
		<el-tooltip class="item" effect="dark" content="返回上页" placement="left">
			<el-button type="primary" icon="el-icon-close" circle @click="back"></el-button>
		</el-tooltip>
	</div>
</template>

<script>
export default {
	methods: {
		// 返回
		back() {
			this.$router.go(-1)
		}
	}
}
</script>

<style scoped>
.close-n-go-back {
	position: fixed;
	display: flex;
	justify-content: space-around;
	align-items: center;
	flex-direction: column;
	bottom: 50px;
	right: 50px;
	width: 50px;
	height: 100px;
}
</style>