<template>
	<div class="psy-score-chart">
		<div class="chart">
			<div class="desc" :style="`flex: 0 0 ${descWidth}`">
				<span>{{ desc }}</span>
			</div>
			<div class="chart-inner" :style="`flex: 0 0 calc(100% - ${descWidth})`" v-if="chartD.length > 0">
				<div class="meter">
					<div 
						v-for="(item, idx) in chartD" 
						:key="idx" 
						class="score-range" 
						:class="'score' + (idx + 1)" 
						:style="dynamicW(item)"
					>
						<span>{{ item.evaResult }}</span>
					</div>
					<div class="pointer" :style="locate()"></div>
				</div>
				<div class="ruler">
					<div
						v-for="(item, idx) in chartD"
						:key="idx"
						class="score-range"
						:class="'score' + (idx + 1)"
						:style="dynamicW(item)"
					>
						<span :class="hideLeftScore(idx)">{{ item.beginScore }}
							<i>分</i>
						</span>
						<span>{{ item.endScore }}</span>
					</div>
				</div>
			</div>
			<div class="chart-inner" v-else>暂无数据</div>
		</div>
	</div>
</template>

<script>
export default {
	data() {
		return {
			// palette: ['#67C23A', '#A7C23A', '#C2B13A', '#E6A23A', '#E6833A', '#F56C3A', '#E65b3A'],
			chartD: [],
			curScore: 0,
			totalScore: 0
		}
	},
	props: {
		// 图表label
		desc: {
			type: String,
			default: '量表：'
		},
		// 图表label宽
		descWidth: {
			type: String,
			default: '50px'
		},
		// 得分
		score: {
			type: Number,
			default: 0
		},
		// 分数模型
		scoreModel: {
			type: Array,
			default: []
		}
	},
	created() {
		this.curScore = this.score
		if (this.scoreModel.length > 0) {
			// 分数从小到大排序
			this.chartD = this.scoreModel.sort((a, b) => a.beginScore - b.beginScore)
			this.chartD.forEach((v, i, arr) => {
				// _gap即当前分数区间宽度所占百分比
				if (i == 0) {
					v._gap = v.endScore
				} else {
					v._gap = v.endScore - arr[i - 1].endScore
				}
			})
			this.totalScore = this.chartD[this.chartD.length - 1].endScore
		}
	},
	methods: {
		// 列动态长度
		dynamicW(item) {
			let w = item._gap / this.totalScore * 100
			return `width: ${w}%;`
		},
		// 定位分数
		locate() {
			let lt = this.curScore / this.totalScore * 100
			return `left: ${lt}%`
		},
		// 是否需要隐藏左侧分数
		hideLeftScore(idx) {
			return idx > 0 ? 'hide-score' : ''
		}
	}
}
</script>

<style scoped>
.psy-score-chart {
	
}
.psy-score-chart .chart {
	display: flex;
	justify-content: flex-start;
	align-items: baseline;
}
.psy-score-chart .chart-inner {
	height: 45px;
}
.psy-score-chart .meter,
.psy-score-chart .ruler {
	position: relative;
	display: flex;
	justify-content: flex-start;
	align-items: center;
}
.psy-score-chart .meter {
	height: 30px;
	color: #FFF;
}
.psy-score-chart .ruler {
	height: 15px;
	color: #555;
}
.psy-score-chart .score-range {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100%;
	font-size: 12px;
}
.psy-score-chart .meter .score-range {
	text-align: center;
}
.psy-score-chart .ruler .score-range {
	display: flex;
	justify-content: space-between;
	align-items: center;
}
.psy-score-chart .meter .score1 {
	background-color: #e65b3c;
}
.psy-score-chart .meter .score2 {
	background-color: #E66F3A;
}
.psy-score-chart .meter .score3 {
	background-color: #e6833c;
}
.psy-score-chart .meter .score4 {
	background-color: #E6A23C;
}
.psy-score-chart .meter .score5 {
	background-color: #c2b13a;
}
.psy-score-chart .meter .score6 {
	background-color: #a7c23a;
}
.psy-score-chart .meter .score7 {
	background-color: #67C23A;
}
.psy-score-chart .meter .pointer {
	position: absolute;
	top: 0;
	width: 1px;
	height: 100%;
}
.psy-score-chart .meter .pointer:before {
	content: '';
	position: absolute;
	top: 0;
	left: 0;
	transform: translate(-50%, -50%);
	width: 0;
	height: 0;
	border-top: 6px solid red;
	border-right: 6px solid transparent;
	border-bottom: 6px solid transparent;
	border-left: 6px solid transparent;
}
.psy-score-chart .meter .pointer:after {
	content: '';
	position: absolute;
	top: 100%;
	left: 0;
	transform: translate(-50%, -50%);
	width: 0;
	height: 0;
	border-top: 6px solid transparent;
	border-right: 6px solid transparent;
	border-bottom: 6px solid red;
	border-left: 6px solid transparent;
}
.psy-score-chart .ruler {
	line-height: 15px;
}
.psy-score-chart .ruler .hide-score {
	color: transparent;
}
</style>